import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import LinkListPage from '../components/LinkListPage';

const industriesQuery = graphql`
  query {
    allDatoCmsIndustryPage {
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`;

export default function IndustriesPage() {
  const data = useStaticQuery(industriesQuery);
  const links = data.allDatoCmsIndustryPage.edges.map((f) => {
    return {
      title: f.node.title,
      slug: '/industry/' + f.node.slug,
    };
  });

  return <LinkListPage title="Industries" links={links} />;
}
